/*
    EXPERIMENTAL!!
    
    Promise Throttle that runs concurrent (parallel) limits based on max

    Currently, only used on local dev machine for testing. If this goes somewhere else, write tests!!!
*/

export class PromiseThrottle {
    queue: any[] = []
    running: { [key: string]: Promise<any> } = {}
    counter: number = 0
    limit: number = 10
    constructor(params?: { limit: number }) {
        if (params && params.limit) {
            this.limit = params.limit
        }
    }

    reset() {
        this.running = {}
        this.queue = []
        this.counter = 0
    }

    // add
    add(cb: () => void) {
        this.queue.push(cb)
    }

    async start() {
        // TODO : throw an error if this start more than once
        const workers = []
        const maxWorkers = this.limit > this.queue.length ? this.queue.length : this.limit
        for (let i = 0; i < maxWorkers; i += 1) {
            workers.push(this.run())
        }

        // TODO could collect all results in an array and pass it back
        if (workers.length > 0) {
            return Promise.all(workers)
        }

        return null
    }

    // run
    private async run() {
        if (Object.keys(this.running).length < this.limit && this.queue.length > 0) {
            const cb = this.queue.shift()

            const prom = cb()

            const uniqueId = this.counter
            this.counter += 1
            this.running[uniqueId] = prom

            try {
                await prom // TODO: store this result and return
            } catch (err) {
                // Do nothing for now
            } finally {
                delete this.running[uniqueId]
                await this.run()
            }
        }
    }
}

export default null
